import moneyPeek from "../assets/logo-Money-Peek.jpg";
import stocklist from "../assets/stocklist-logo.jpg";
import tasky from "../assets/tasky-logo.jpg";
import pillTime from "../assets/pill-time-logo.jpg";
import croness from "../assets/croness_logo.png";
import playable from "../assets/Playable-logo.png";

export const data = [
  {
    img: stocklist,
    mainTitle: "Stocklist App",
    projectDescription:
      "Improve efficiency with this inventory & stock management app",
    techUsed: "MERN STACK ",
    visitLink: "https://stocklist.vercel.app/",
  },
  {
    img: croness,
    mainTitle: "Croness",
    projectDescription:
      "Calendar for appointments with automatic SMS and email notifications",
    techUsed: "REACT | BOOSTRAP",
    visitLink: "https://croness.radu-rusu.com/",
  },
  {
    img: moneyPeek,
    mainTitle: "Money Peek",
    projectDescription:
      "Financial management tool designed to help customers track and manage their personal finances",
    techUsed: "REACT | TS",
    visitLink: "https://money-peek.radu-rusu.com/",
  },

  {
    img: tasky,
    mainTitle: "Tasky",
    projectDescription:
      "Effortlessly add, delete, update, mark as complete, or move tasks with Tasky",
    techUsed: "MERN STACK",
    visitLink: "https://www.tasky.radu-rusu.com/",
  },
  {
    img: playable,
    mainTitle: "Playable",
    projectDescription:
      "Seamless, intuitive music player featuring responsive design",
    techUsed: "REACT JS",
    visitLink: "https://playable.radu-rusu.com/",
  },

  {
    img: pillTime,
    mainTitle: "Pill-Time",
    projectDescription:
      "Easily keep track of your medicine intake with Pill-Time",
    techUsed: "MERN STACK",
    visitLink: "https://pill-time.radu-rusu.com/",
  },
];
