import Career from "./components/Career";
import ContactForm from "./components/ContactForm";
import Experience from "./components/Experience";
import Footer from "./components/Footer";
import LandingPage from "./components/LandingPage";
import LatestProjects from "./components/LatestProjects";
import NavbarComponent from "./components/NavbarComponent";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <NavbarComponent />
      <LandingPage />
      <Career />
      <Experience />
      <LatestProjects />
      <ContactForm />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
