import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/Logo.png";
import "../styles/NavbarComponent.scss";

const NavbarComponent = () => {
  const [activeLink, setActiveLink] = useState(null);

  const handleClick = (link) => {
    setActiveLink(link);
  };

  const handleLogoClick = () => {
    setActiveLink(null);
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home" onClick={handleLogoClick}>
          <img className="navbar-logo" src={logo} alt="radu-logo" />
        </Navbar.Brand>
        <Nav className="ml-auto">
          <Nav.Link
            href="#projects"
            onClick={() => handleClick("projects")}
            active={activeLink === "projects"}
          >
            <span>W</span>
            <span>O</span>
            <span>R</span>
            <span>K</span>
          </Nav.Link>
          <Nav.Link
            href="#contact"
            onClick={() => handleClick("contact")}
            active={activeLink === "contact"}
          >
            <span>C</span>
            <span>O</span>
            <span>N</span>
            <span>T</span>
            <span>A</span>
            <span>C</span>
            <span>T</span>
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
