import React, { useEffect, useState } from "react";
import "../styles/ScrollToTop.scss"

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const checkVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    return () => window.removeEventListener("scroll", checkVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`scroll-to-top ${isVisible ? "visible" : ""}`}
      onClick={scrollToTop}
    >
      &#x25B2;
    </div>
  );
};

export default ScrollToTop;
