import React from "react";
import "../styles/Footer.scss";
import { Container } from "react-bootstrap";
import linkedin from "../assets/linkedin.png";

const Footer = () => {
  return (
    <footer>
      <Container fluid>
        <Container>
          <div className="copyright">
            ©2023{" "}
            <a
              href="https://radu-rusu.com/"
              rel="noopener nofollow blank noreferrer"
            >
              RADU RUSU
            </a>{" "}
            - All rights reserved{" "}
          </div>
          <a
            href="https://www.linkedin.com/in/radu-web-dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="linkedin logo" />
          </a>
        </Container>
      </Container>
    </footer>
  );
};

export default Footer;
