import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { data } from "../utils/projects-data";
import "../styles/LatestProjects.scss";

const ProjectCard = ({ project, index }) => {
  return (
    <a
      href={project.visitLink}
      target="_blank"
      rel="noopener noreferrer"
      className="project-links"
    >
      <div className={`project-card project${index + 1}`}>
        <div className="project-content">
          <img
            className="project-logo"
            src={project.img}
            alt={`${project.mainTitle} logo`}
          />
          <p className="project-description">{project.projectDescription}</p>
          <p className="tech-used">{project.techUsed}</p>
        </div>
      </div>
    </a>
  );
};

const LatestProjects = () => {
  return (
    <Container className="latest-projects" id="projects">
      <h3 className="title">Latest Projects</h3>
      <br />
      <hr />
      <br />
      <code>
        Note: Some projects may not load instantly due to the use of free
        deployment services for most apps.
      </code>
      <Row>
        {data.map((project, index) => (
          <Col key={index} xs={12} md={6} lg={4} className="mb-4">
            <ProjectCard project={project} index={index} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default LatestProjects;
