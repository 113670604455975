import React from "react";
import "../styles/Experience.scss";
import { Container } from "react-bootstrap";

const Experience = () => {
  const timelineData = [
    { year: "2012", title: "Business Bachelor's" },
    { year: "2014", title: "Masters in Psychology" },
    { year: "2016", title: "Supervisor at HOF" },
    { year: "2018", title: "Manager at Primark" },
    { year: "2020", title: "Manager at Lidl" },
    { year: "2021", title: "Front Dev at Boots" },
  ];

  return (
    <div className="experience-container">
      <h3 className="title">Experience & Education</h3>
      <div className="timeline">
        <Container fluid className="timeline-group">
          {timelineData.slice(0, 3).map((item, index) => (
            <div key={index} className="timeline-item">
              <div className="circle" />
              <div className="details">
                <div className="year">{item.year}</div>
                <p className="experience-title">{item.title}</p>
              </div>
            </div>
          ))}
        </Container>
        <Container fluid className="timeline-group">
          {timelineData.slice(3).map((item, index) => (
            <div key={index} className="timeline-item">
              <div className="circle" />
              <div className="details">
                <div className="year">{item.year}</div>
                <p className="experience-title">{item.title}</p>
              </div>
            </div>
          ))}
        </Container>
      </div>
    </div>
  );
};

export default Experience;
