import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import photo from "../assets/photo_radu.jpg";
import "../styles/LandingPage.scss";
import { Link, useNavigate } from "react-router-dom";

const LandingPage = () => {
  const targetEl = useRef(null);

  const navigate = useNavigate();

  const handleGetInTouchClick = () => {
    navigate("/#contact");
    const contactElement = document.getElementById("contact");
    if (contactElement) {
      contactElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const paragraphText = targetEl.current.textContent;
    targetEl.current.textContent = "";

    paragraphText.split("").forEach((char) => {
      const span = document.createElement("span");
      span.textContent = char;
      if (char === " ") {
        span.classList.add("space");
      } else {
        span.classList.add("character");
      }
      targetEl.current.appendChild(span);
    });

    const characters = targetEl.current.querySelectorAll(".character");
    characters.forEach((char) => {
      char.addEventListener("mouseover", (e) => {
        e.target.classList.add("hovered");
        if (e.target.previousSibling) {
          e.target.previousSibling.classList.add("adjacent");
        }
        if (e.target.nextSibling) {
          e.target.nextSibling.classList.add("adjacent");
        }
      });

      char.addEventListener("mouseout", (e) => {
        e.target.classList.remove("hovered");
        if (e.target.previousSibling) {
          e.target.previousSibling.classList.remove("adjacent");
        }
        if (e.target.nextSibling) {
          e.target.nextSibling.classList.remove("adjacent");
        }
      });
    });
  }, []);

  return (
    <div className="landing-page-wrapper">
      <Container>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <div className="left-text">
              <div>
                <h3 className="landing-title">RADU RUSU</h3>
                <span className="subtitle">WEB DEVELOPER</span>
              </div>
              <hr />
              <p ref={targetEl} className="hov">
                As I dive deeper into backend development, I'm thrilled to blend
                my skills, passion, and drive with your ideas. Let's join forces
                and create amazing digital experiences that leave a lasting
                impression. Are you in? Let's connect and get started on this
                exciting journey!
              </p>
              <button className="button" onClick={handleGetInTouchClick}>
                {" "}
                Get in touch
              </button>
              {/* <Link to="/#contact" className="button">
                Get in touch
              </Link> */}
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="photo-col">
            <div className="photo-wrapper">
              <div className="main-photo">
                <img src={photo} alt="" />
              </div>
              <span className="photo-circle"></span>
              <span className="photo-circle photo-circle2"></span>
            </div>
          </Col>
          <Col xs={12} md={12} lg={4} className="right-col">
            <div className="right-text-container">
              <div className="right-text">
                <div>
                  <h3 className="vertical-numbers">40+</h3>
                  <hr />
                  <p>COMPLETED PROJECTS</p>
                </div>
                <div>
                  <h3 className="vertical-numbers">3+</h3>
                  <hr />
                  <p>YEARS OF EXPERIENCE</p>
                </div>
                <div>
                  <h3 className="vertical-numbers">
                    <span>￡</span>1M+
                  </h3>
                  <hr />
                  <p>REVENUE GENERATED</p>
                </div>
                <div>
                  <h3 className="vertical-numbers">700+</h3>
                  <hr />
                  <p>ASSETS BUILD</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mouse-container">
          <div className="mouse"></div>
        </div>
      </Container>
    </div>
  );
};

export default LandingPage;
