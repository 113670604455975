import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/Career.scss";

const Career = () => {
  const handleDownload = () => {
    // const fileURL = `${process.env.PUBLIC_URL}/cv_Radu_Rusu.pdf`;
    // const link = document.createElement("a");
    // link.href = fileURL;
    // link.download = "cv_Radu_Rusu.pdf";
    // link.target = "_blank";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  return (
    <div className="career-container">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} lg={6} className="skills-col">
            <div className="skills-container">
              <span className="skills">HTML</span>
              <span className="skills">CSS</span>
              <span className="skills">JAVASCRIPT</span>
              <span className="skills">RESPONSIVE DESIGN</span>
              <span className="skills">GIT & GITHUB</span>
              <span className="skills">MANGO DB</span>
              <span className="skills">EXPRESS JS</span>
              <span className="skills">REACT JS</span>
              <span className="skills">NODE JS</span>
              <span className="skills">PERFORMANCE OPTIMIZATION</span>
              <span className="skills">BOOSTRAP</span>
              <span className="skills">ADOBE SUITE</span>
              <span className="skills">SASS</span>
              <span className="skills">WORDPRESS</span>
              <span className="skills">JQUERY</span>
              <span className="skills">DESIGN</span>
              <span className="skills">SEO</span>
            </div>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <div className="career-text-container">
              <h3 className="title">My career so far</h3>
              <hr />
              <p>
                Ever since I typed "Hello World" and saw it magically appear on
                the screen of my very first website, I knew that web development
                was more than just a career path - it was a calling. As a
                dedicated frontend developer with a recent venture into backend
                development, I've found that this world offers endless
                opportunities to learn, grow, and master the art of creating
                exceptional websites and applications
              </p>
            </div>
            <button
              className="button1-styles down__button"
              type="button"
              onClick={handleDownload}
            >
              <span className="down__button__text">Download my CV</span>
              <span className="down__button__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 35 35"
                  id="bdd05811-e15d-428c-bb53-8661459f9307"
                  data-name="Layer 2"
                  className="svg"
                >
                  <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
                  <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
                  <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
                </svg>
              </span>
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Career;
