import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "../styles/ContactForm.scss";
import { Container } from "react-bootstrap";

const ContactForm = () => {
  const form = useRef();
  const [showModal, setShowModal] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_u9t5jqo",
        "template_gmail_send",
        form.current,
        "fIbfAx0PjjHjifTYK"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
          setShowModal(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <button className="close-modal" onClick={closeModal}>
              X
            </button>
            <p>Message sent successfully!</p>
          </div>
        </div>
      )}
      <Container className="contact" id="contact">
        <h3 className="title">Let's work together!</h3>
        <p className="subtitle">
          I'd love to hear about your project or ideas!{" "}
          <br className="br-only-mobile" /> Drop me a message.
          <br /> I usually respond <span>within a few hours.</span>
        </p>
        <form ref={form} onSubmit={sendEmail}>
          <div className="wave-group">
            <input type="text" name="user_name" required className="input" />
            <span className="bar"></span>
            <label className="label">Name</label>
          </div>
          <div className="wave-group">
            <input type="email" name="user_email" required className="input" />
            <span className="bar"></span>
            <label className="label">Email</label>
          </div>
          <div className="wave-group">
            <textarea name="message" required className="input" />
            <span className="bar"></span>
            <label className="label">Message</label>
          </div>
          <button type="submit" value="Send" className="button contact-button">
            Send
          </button>
        </form>
      </Container>
    </>
  );
};

export default ContactForm;
